import React, { useEffect } from "react";

import Main from '../components/Home/Main'
import TradeCFDs from "../components/Home/TradeCFDs";
import Experience from "../components/Home/Experience";
import Has from "../components/Home/Has";
import Still from "../components/Home/Still";
import SeeWhat from "../components/Home/SeeWhat";
import GlobalOffices from "../components/Home/GlobalOffices";
import OurFund from "../components/Home/OurFund";

function Home () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <TradeCFDs/>
            <Experience/>
            <Has/>
            <Still/>
            <SeeWhat/>
            <GlobalOffices/>
            <OurFund/>
        </div>
    );
}

export default Home;