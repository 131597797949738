import React, { useEffect } from "react";

import Main from '../components/Security/Main'
import Aditional from "../components/Security/Aditional";
import AccountInsurance from "../components/Security/AccountInsurance";
import Negative from "../components/Security/Negative";
import IsRight from "../components/Security/IsRight";

function Security () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <Aditional/>
            <AccountInsurance/>
            <Negative/>
            <IsRight/>
        </div>
    );
}

export default Security;