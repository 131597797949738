import React from "react";

import { useTranslation, Trans } from "react-i18next";

import has from '../../img/Home/has_group.png'
import has_mob from '../../img/Home/has_group_mob.png'

function Has () {

    const { t } = useTranslation();
      
    return(
        <div className="overflow-hidden relative pt-[80px] xl:pt-[160px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto overflow-hidden relative rounded-[40px]">
                <img src={has} alt="" className="hidden xl:block"/>
                <img src={has_mob} alt="" className="xl:hidden block h-[664px] w-full object-cover"/>
                <div className="inset-[40px] xl:inset-[60px] absolute">
                    <p className="text-[16px] xl:text-[20px] roboto text-[#000000B2] max-w-[540px]">{t('home.18')}</p>
                </div>
            </div>
        </div>
    );
}

export default Has;