import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import i18n from "i18next";
import Cookies from "js-cookie";
import logo from "../img/logo.png";
import logo_mob from "../img/logo.png";
import MobileMenu from "./MobileMenu";

function Header() {
    const { t } = useTranslation();
    const location = useLocation();

    const languages = [
        { value: 'en', label: 'EN' },
        { value: 'ru', label: 'RU' },
        { value: 'it', label: 'IT' },
        { value: 'pl', label: 'PL' },
        { value: 'de', label: 'DE' },
    ]

    const storedLang = Cookies.get("i18next");
    const [currentLang, setCurrentLang] = useState(storedLang || 'en');

    useEffect(() => {
        if (storedLang && storedLang !== currentLang) {
            setCurrentLang(storedLang);
            i18n.changeLanguage(storedLang);
        }
        // eslint-disable-next-line
        document.body.setAttribute('dir', storedLang === 'ar' ? 'rtl' : 'ltr');
    }, [storedLang]);
    

    const handleChangeLang = (selectedLang) => {
        setCurrentLang(selectedLang.value);
        i18n.changeLanguage(selectedLang.value);
        Cookies.set("i18next", selectedLang.value);
    };

    return (
        <div className="header-container">
            <div className="hidden max-w-[1280px] h-[60px] justify-between mx-auto xl:flex items-center">
                <Link to="/"><img src={logo} alt="" className="w-[80px]"/></Link>
                <Link to="/products"><p className={`text-[16px] font-light roboto ${location.pathname === '/products' ? 'text-[#145B45]' : 'text-[#000000B2]'}`}>{t('header1')}</p></Link>
                <Link to="/security"><p className={`text-[16px] font-light roboto ${location.pathname === '/security' ? 'text-[#145B45]' : 'text-[#000000B2]'}`}>{t('header2')}</p></Link>
                <Link to="/accounts"><p className={`text-[16px] font-light roboto ${location.pathname === '/accounts' ? 'text-[#145B45]' : 'text-[#000000B2]'}`}>{t('header3')}</p></Link>
                <Link to="/education"><p className={`text-[16px] font-light roboto ${location.pathname === '/education' ? 'text-[#145B45]' : 'text-[#000000B2]'}`}>{t('header4')}</p></Link>
                <Link to="/aboutus"><p className={`text-[16px] font-light roboto ${location.pathname === '/aboutus' ? 'text-[#145B45]' : 'text-[#000000B2]'}`}>{t('header5')}</p></Link>
                <Dropdown
                    options={languages}
                    placeholder="EN"
                    onChange={handleChangeLang}
                    value={languages.find(lang => lang.value === currentLang)}
                />
                <a href="https://user.asset-wealth.org/">
                    <div className="button cursor-pointer">
                        <p className="inter text-white text-[16px]">{t('header6')}</p>
                    </div>
                </a>
            </div>
            <div className="xl:hidden flex mx-[20px] items-center justify-between h-[60px]">
                <Link to="/"><img src={logo_mob} alt="" className="w-[80px]"/></Link>
                <Dropdown
                    options={languages}
                    placeholder="EN"
                    onChange={handleChangeLang}
                    value={languages.find(lang => lang.value === currentLang)}
                    className="ml-auto mr-[20px]"
                />
                <MobileMenu/>
            </div>
        </div>
    );
}

export default Header;
