import React, { useEffect } from "react";

import Main from '../components/Education/Main'
import Introduction from "../components/Education/Introduction";
import Financial from "../components/Education/Financial";

function Education () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <Introduction/>
            <Financial/>
        </div>
    );
}

export default Education;