import React, { useEffect } from "react";

import Main from '../components/Products/Main'
import Tools from "../components/Products/Tools";
import Productss from "../components/Products/Productss";
import WhyTrade from "../components/Products/WhyTrade";
import Pricing from "../components/Products/Pricing";

function Products () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <Tools/>
            <Productss/>
            <WhyTrade/>
            <Pricing/>
        </div>
    );
}

export default Products;