import React from "react";

import { useTranslation, Trans } from "react-i18next";

import yes from '../../img/AccountTypes/yes.png'
import no from '../../img/AccountTypes/no.png'

function AccountTypes () {

    const { t } = useTranslation();
      
    return(
        <div className="relative overflow-hidden pt-[80px] xl:pt-[160px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto overflow-hidden overflow-x-auto">
                <div className="flex justify-between items-center w-[1280px]">
                    <div className="w-[228px]">
                        <p className="roboto text-[14px] xl:text-[16px] ml-[20px]">{t('accounttypes.4')}</p>
                    </div>
                    <div className="w-[228px] h-[75px] rounded-[40px] bg-[#145B45] border border-[#313131] flex items-center justify-center">
                        <p className="text-[16px] xl:text-[24px] manrope font-[500] text-white">{t('accounttypes.5')}</p>
                    </div>
                    <div className="w-[228px] h-[75px] rounded-[40px] silver flex items-center justify-center">
                        <p className="text-[16px] xl:text-[24px] manrope font-[500] text-black">{t('accounttypes.6')}</p>
                    </div>
                    <div className="w-[228px] h-[75px] rounded-[40px] gold flex items-center justify-center">
                        <p className="text-[16px] xl:text-[24px] manrope font-[500] text-black">{t('accounttypes.7')}</p>
                    </div>
                    <div className="w-[228px] h-[75px] rounded-[40px] platinum flex items-center justify-center">
                        <p className="text-[16px] xl:text-[24px] manrope font-[500] text-white">{t('accounttypes.8')}</p>
                    </div>
                </div>
                <div className="flex justify-between items-center h-[68px] rounded-[10px] bg-[#EBEBEB] mt-[30px] w-[1280px]">
                    <div className="w-[228px]">
                        <p className="ml-[20px] text-[#000000B2] roboto text-[14px] xl:text-[16px] font-[300]">{t('accounttypes.9')}</p>
                    </div>
                    <div className="w-[228px] flex">
                        <p className="roboto text-[14px] xl:text-[16px] mx-auto">250$</p>
                    </div>
                    <div className="w-[228px] flex">
                        <p className="roboto text-[14px] xl:text-[16px] mx-auto">$1.000</p>
                    </div>
                    <div className="w-[228px] flex">
                        <p className="roboto text-[14px] xl:text-[16px] mx-auto">$10.000</p>
                    </div>
                    <div className="w-[228px] flex">
                        <p className="roboto text-[14px] xl:text-[16px] mx-auto">$50.000</p>
                    </div>
                </div>
                <div className="flex justify-between items-center h-[68px] rounded-[10px] w-[1280px]">
                    <div className="w-[228px]">
                        <p className="ml-[20px] text-[#000000B2] roboto text-[14px] xl:text-[16px] font-[300]">{t('accounttypes.10')}</p>
                    </div>
                    <div className="w-[228px] flex">
                        <p className="roboto text-[14px] xl:text-[16px] mx-auto">0.1 lot</p>
                    </div>
                    <div className="w-[228px] flex">
                        <p className="roboto text-[14px] xl:text-[16px] mx-auto">0.1 lot</p>
                    </div>
                    <div className="w-[228px] flex">
                        <p className="roboto text-[14px] xl:text-[16px] mx-auto">0.1 lot</p>
                    </div>
                    <div className="w-[228px] flex">
                        <p className="roboto text-[14px] xl:text-[16px] mx-auto">0.1 lot</p>
                    </div>
                </div>
                <div className="flex justify-between items-center h-[68px] rounded-[10px] bg-[#EBEBEB] w-[1280px]">
                    <div className="w-[228px]">
                        <p className="ml-[20px] text-[#000000B2] roboto text-[14px] xl:text-[16px] font-[300]">{t('accounttypes.11')}</p>
                    </div>
                    <div className="w-[228px] flex">
                        <p className="roboto text-[14px] xl:text-[16px] mx-auto">Variable</p>
                    </div>
                    <div className="w-[228px] flex">
                        <p className="roboto text-[14px] xl:text-[16px] mx-auto">Variable</p>
                    </div>
                    <div className="w-[228px] flex">
                        <p className="roboto text-[14px] xl:text-[16px] mx-auto">0.0 pips</p>
                    </div>
                    <div className="w-[228px] flex">
                        <p className="roboto text-[14px] xl:text-[16px] mx-auto">0.0 pips</p>
                    </div>
                </div>
                <div className="flex justify-between items-center h-[68px] rounded-[10px] w-[1280px]">
                    <div className="w-[228px]">
                        <p className="ml-[20px] text-[#000000B2] roboto text-[14px] xl:text-[16px] font-[300]">{t('accounttypes.12')}</p>
                    </div>
                    <div className="w-[228px] flex">
                        <p className="roboto text-[14px] xl:text-[16px] mx-auto">Zero</p>
                    </div>
                    <div className="w-[228px] flex">
                        <p className="roboto text-[14px] xl:text-[16px] mx-auto">$3/lot per side</p>
                    </div>
                    <div className="w-[228px] flex">
                        <p className="roboto text-[14px] xl:text-[16px] mx-auto">$2.5/lot per side</p>
                    </div>
                    <div className="w-[228px] flex">
                        <p className="roboto text-[14px] xl:text-[16px] mx-auto">Zero</p>
                    </div>
                </div>
                <div className="flex justify-between items-center h-[68px] rounded-[10px] bg-[#EBEBEB] w-[1280px]">
                    <div className="w-[228px]">
                        <p className="ml-[20px] text-[#000000B2] roboto text-[14px] xl:text-[16px] font-[300]">{t('accounttypes.13')}</p>
                    </div>
                    <div className="w-[228px] flex">
                        <p className="roboto text-[14px] xl:text-[16px] mx-auto">500 to 1</p>
                    </div>
                    <div className="w-[228px] flex">
                        <p className="roboto text-[14px] xl:text-[16px] mx-auto">500 to 1</p>
                    </div>
                    <div className="w-[228px] flex">
                        <p className="roboto text-[14px] xl:text-[16px] mx-auto">500 to 1</p>
                    </div>
                    <div className="w-[228px] flex">
                        <p className="roboto text-[14px] xl:text-[16px] mx-auto">100 to 1</p>
                    </div>
                </div>
                <div className="flex justify-between items-center h-[68px] rounded-[10px] w-[1280px]">
                    <div className="w-[228px]">
                        <p className="ml-[20px] text-[#000000B2] roboto text-[14px] xl:text-[16px] font-[300]">{t('accounttypes.14')}</p>
                    </div>
                    <div className="w-[228px] flex">
                        <img src={yes} alt="" className="mx-auto"/>
                    </div>
                    <div className="w-[228px] flex">
                        <img src={no} alt="" className="mx-auto"/>
                    </div>
                    <div className="w-[228px] flex">
                        <img src={no} alt="" className="mx-auto"/>
                    </div>
                    <div className="w-[228px] flex">
                        <img src={yes} alt="" className="mx-auto"/>
                    </div>
                </div>
                <div className="flex justify-between items-center h-[68px] rounded-[10px] bg-[#EBEBEB] w-[1280px]">
                    <div className="w-[228px]">
                        <p className="ml-[20px] text-[#000000B2] roboto text-[14px] xl:text-[16px] font-[300]">{t('accounttypes.15')}</p>
                    </div>
                    <div className="w-[228px] flex">
                        <p className="roboto text-[14px] xl:text-[16px] mx-auto text-center max-w-[149px]">USD, AUD, EUR, GBP, NZD, CAD, JPY</p>
                    </div>
                    <div className="w-[228px] flex">
                        <p className="roboto text-[14px] xl:text-[16px] mx-auto text-center max-w-[149px]">USD, AUD, EUR, GBP, NZD, CAD, JPY</p>
                    </div>
                    <div className="w-[228px] flex">
                        <p className="roboto text-[14px] xl:text-[16px] mx-auto text-center max-w-[149px]">USD, AUD, EUR, GBP, NZD, CAD, JPY</p>
                    </div>
                    <div className="w-[228px] flex">
                        <p className="roboto text-[14px] xl:text-[16px] mx-auto text-center max-w-[149px]">USD, AUD, EUR, GBP, NZD, CAD, JPY</p>
                    </div>
                </div>
                <div className="flex justify-between items-center h-[68px] rounded-[10px] w-[1280px]">
                    <div className="w-[228px]">
                        <p className="ml-[20px] text-[#000000B2] roboto text-[14px] xl:text-[16px] font-[300]">{t('accounttypes.16')}</p>
                    </div>
                    <div className="w-[228px] flex">
                        <img src={yes} alt="" className="mx-auto"/>
                    </div>
                    <div className="w-[228px] flex">
                        <img src={yes} alt="" className="mx-auto"/>
                    </div>
                    <div className="w-[228px] flex">
                        <img src={yes} alt="" className="mx-auto"/>
                    </div>
                    <div className="w-[228px] flex">
                        <img src={yes} alt="" className="mx-auto"/>
                    </div>
                </div>
                <div className="flex justify-between items-center h-[68px] rounded-[10px] bg-[#EBEBEB] w-[1280px]">
                    <div className="w-[228px]">
                        <p className="ml-[20px] text-[#000000B2] roboto text-[14px] xl:text-[16px] font-[300]">{t('accounttypes.17')}</p>
                    </div>
                    <div className="w-[228px] flex">
                        <img src={no} alt="" className="mx-auto"/>
                    </div>
                    <div className="w-[228px] flex">
                        <img src={no} alt="" className="mx-auto"/>
                    </div>
                    <div className="w-[228px] flex">
                        <img src={no} alt="" className="mx-auto"/>
                    </div>
                    <div className="w-[228px] flex">
                        <img src={no} alt="" className="mx-auto"/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AccountTypes;