import React, { useState } from "react";

import { useTranslation, Trans } from "react-i18next";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';

import sw1 from '../../img/Products/swiper_tools/sw1.png'
import sw2 from '../../img/Products/swiper_tools/sw2.png'
import sw3 from '../../img/Products/swiper_tools/sw3.png'
import sw4 from '../../img/Products/swiper_tools/sw4.png'
import sw5 from '../../img/Products/swiper_tools/sw5.png'
import sw6 from '../../img/Products/swiper_tools/sw6.png'

const data = [
    {
        name: "products.6",
        text: "products.7",
        sw: sw1,
    },
    {
        name: "products.8",
        text: "products.9",
        sw: sw2,
    },
    {
        name: "products.10",
        text: "products.11",
        sw: sw3,
    },
    {
        name: "products.12",
        text: "products.13",
        sw: sw4,
    },
    {
        name: "products.14",
        text: "products.15",
        sw: sw5,
    },
    {
        name: "products.16",
        text: "products.17",
        sw: sw6,
    },
]

function Tools () {

    const { t } = useTranslation();

    const [swiper, setSwiper] = useState({});
      
    return(
        <div className="overflow-hidden relative pt-[80px] xl:pt-[160px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col xl:flex-row justify-between items-end">
                <div>
                    <p className="manrope leading-[1.2] text-[24px] xl:text-[48px] text-black spanmain"><Trans>{t('products.4')}</Trans></p>
                    <p className="roboto text-[16px] xl:text-[20px] text-[#000000B2] max-w-[393px] mt-[10px]">{t('products.5')}</p>
                </div>
                <div>
                    <div className='flex xl:gap-[40px]'>
                        <div onClick={() => swiper.slidePrev()} className="button_swiper cursor-pointer scale-75 xl:scale-100">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M19 12L5 12" stroke="#145B45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12 5L5 12L12 19" stroke="#145B45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>  
                        </div>
                        <div onClick={() => swiper.slideNext()} className="button_swiper cursor-pointer scale-75 xl:scale-100">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M5 12L19 12" stroke="#145B45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12 19L19 12L12 5" stroke="#145B45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mx-[20px] xl:mx-auto mt-[20px] xl:mt-[60px]">
                <Swiper
                        modules={[Navigation, Pagination, Autoplay]}
                        slidesPerView={1}
                        loop={true}
                        onInit={(e) => {
                            setSwiper(e);
                        }}
                        centeredSlides={true}
                        breakpoints={{
                            300: {
                            slidesPerView: 1,
                            },
                            1280: {
                            slidesPerView: 2.5,
                            },
                            1680: {
                            slidesPerView: 3.5,
                            },
                            1920: {
                            slidesPerView: 4.5,
                            },
                        }}
                    >
                        {data.map((dat, index) => (
                            <SwiperSlide key={index}>
                                <div className="xl:w-[393px] h-[282px] bg-[#EBEBEB] rounded-[40px] p-[20px] xl:p-[40px]">
                                    <img src={dat.sw} alt="" />
                                    <p className="mb-[6px] text-[#145B45] manrope text-[20px] xl:text-[24px] mt-[40px]">{t(dat.name)}</p>
                                    <p className="mt-[20px] text-[16px] text-[#000000B2] roboto leading-[1.2] opacity-[0.7]">{t(dat.text)}</p>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
            </div>
        </div>
    );
}

export default Tools;