import React from "react";

import { useTranslation, Trans } from "react-i18next";

import sw1 from '../../img/AboutUs/sw(1).png'
import sw2 from '../../img/AboutUs/sw(2).png'
import sw3 from '../../img/AboutUs/sw(3).png'
import sw4 from '../../img/AboutUs/sw(4).png'

function OurValue () {

    const { t } = useTranslation();

    return(
        <div className="overflow-hidden relative pt-[80px] xl:pt-[160px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto z-50 relative">
                <p className="manrope leading-[1.4] text-[24px] xl:text-[48px] text-black spanmain"><Trans>{t('aboutus.11')}</Trans></p>
                <p className="roboto leading-[1.5] text-[14px] xl:text-[16px] text-[#000000B2] mt-[20px] max-w-[600px]"><Trans>{t('aboutus.12')}</Trans></p>
                <div className="flex flex-col xl:flex-row justify-between mt-[20px] xl:mt-[60px]">
                    <div className="xl:w-[517px]">
                        <div className="h-[100px] rounded-[20px] bg-[#EBEBEB] flex items-center justify-between px-[20px]">
                            <p className="manrope text-[16px] xl:text-[24px] font-[500] text-[#145B45]">{t('aboutus.13')}</p>
                            <img src={sw1} alt="" />
                        </div>
                        <p className="max-w-[497px] ml-[20px] mt-[20px] roboto font-[300] text-[14px] xl:text-[16px] text-[#000000B2]">{t('aboutus.14')}</p>
                    </div>
                    <div className="xl:w-[517px] mt-[20px] xl:mt-0">
                        <div className="h-[100px] rounded-[20px] bg-[#EBEBEB] flex items-center justify-between px-[20px]">
                            <p className="manrope text-[16px] xl:text-[24px] font-[500] text-[#145B45]">{t('aboutus.15')}</p>
                            <img src={sw2} alt="" />
                        </div>
                        <p className="max-w-[497px] ml-[20px] mt-[20px] roboto font-[300] text-[14px] xl:text-[16px] text-[#000000B2]">{t('aboutus.16')}</p>
                    </div>
                </div>
                <div className="flex flex-col xl:flex-row justify-between mt-[20px] xl:mt-[60px]">
                    <div className="xl:w-[517px]">
                        <div className="h-[100px] rounded-[20px] bg-[#EBEBEB] flex items-center justify-between px-[20px]">
                            <p className="manrope text-[16px] xl:text-[24px] font-[500] text-[#145B45]">{t('aboutus.17')}</p>
                            <img src={sw1} alt="" />
                        </div>
                        <p className="max-w-[497px] ml-[20px] mt-[20px] roboto font-[300] text-[14px] xl:text-[16px] text-[#000000B2]">{t('aboutus.18')}</p>
                    </div>
                    <div className="xl:w-[517px] mt-[20px] xl:mt-0">
                        <div className="h-[100px] rounded-[20px] bg-[#EBEBEB] flex items-center justify-between px-[20px]">
                            <p className="manrope text-[16px] xl:text-[24px] font-[500] text-[#145B45]">{t('aboutus.19')}</p>
                            <img src={sw2} alt="" />
                        </div>
                        <p className="max-w-[497px] ml-[20px] mt-[20px] roboto font-[300] text-[14px] xl:text-[16px] text-[#000000B2]">{t('aboutus.20')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OurValue;