import React from "react";

import { useTranslation } from "react-i18next";

import Marquee from "react-fast-marquee";

import m1 from '../../img/Home/marque/m1.png'
import m2 from '../../img/Home/marque/m2.png'
import m3 from '../../img/Home/marque/m3.png'
import m4 from '../../img/Home/marque/m4.png'
import m5 from '../../img/Home/marque/m5.png'
import m6 from '../../img/Home/marque/m6.png'
import m7 from '../../img/Home/marque/m7.png'

const data = [
    {
        img: m1
    },
    {
        img: m2
    },
    {
        img: m3
    },
    {
        img: m4
    },
    {
        img: m5
    },
    {
        img: m6
    },
    {
        img: m7
    },
]

function OurFund () {

    const { t } = useTranslation();
      
    return(
        <div className="overflow-hidden relative pt-[80px] xl:pt-[160px]">
            <div className="mx-[20px] xl:mx-auto flex flex-col">
                <p className="manrope leading-[1.2] text-[24px] xl:text-[48px] text-black mx-auto max-w-[556px] text-center">{t('home.32')}</p>
                <Marquee className="mt-[20px] xl:mt-[60px]" autoFill>
                    {data.map((dat, index) => (
                        <div key={index} className="w-[130px] xl:w-[290px] h-[80px] p-[20px] xl:p-0 xl:h-[129px] rounded-[30px] xl:rounded-[40px] bg-[#EBEBEB] flex items-center justify-center mx-[10px]">
                            <img src={dat.img} alt="" />
                        </div>
                    ))}
                </Marquee>
            </div>
        </div>
    );
}

export default OurFund;