import React, { useEffect } from "react";

import Main from '../components/AccountTypes/Main'
import AccountTypess from '../components/AccountTypes/AccountTypes'

function AccountTypes () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <AccountTypess/>
        </div>
    );
}

export default AccountTypes;