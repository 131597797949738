import React from "react";

import { useTranslation, Trans } from "react-i18next";

import vec2 from '../../img/AboutUs/vec2.png'

function Traders () {

    const { t } = useTranslation();

    return(
        <div className="overflow-hidden relative pt-[80px] xl:pt-[160px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto z-50 relative flex flex-col xl:flex-row-reverse justify-between items-center">
                <div className="xl:w-[517px] w-full">
                    <p className="manrope leading-[1.4] text-[24px] xl:text-[48px] text-[#145B45] font-[500] border-b">100,000+ <span className="text-[16px] xl:text-[24px]">{t('aboutus.25')}</span></p>
                    <p className="manrope leading-[1.4] text-[24px] xl:text-[48px] text-[#145B45] font-[500] border-b mt-[30px]">24/7 <span className="text-[16px] xl:text-[24px]">{t('aboutus.26')}</span></p>
                    <p className="manrope leading-[1.4] text-[24px] xl:text-[48px] text-[#145B45] font-[500] border-b mt-[30px]">{t('aboutus.27a')} <span className="text-[16px] xl:text-[24px]">{t('aboutus.27')}</span></p>
                    <p className="manrope leading-[1.4] text-[24px] xl:text-[48px] text-[#145B45] font-[500] border-b mt-[30px]">75 <span className="text-[16px] xl:text-[24px]">{t('aboutus.28')}</span></p>
                </div>
                <div className="mt-[20px] xl:mt-0">
                    <img src={vec2} alt="" />
                </div>
            </div>
        </div>
    );
}

export default Traders;