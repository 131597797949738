import React from "react";

import { useTranslation, Trans } from "react-i18next";

import main from '../../img/Home/main.png'
import main_tab from '../../img/Home/main-tab.png'
import main_mob from '../../img/Home/main_mob.png'

import Button from '../Button'

function Main () {

    const { t } = useTranslation();
      
    return(
        <div className="overflow-hidden relative h-[763px]">
            <img src={main} alt="" className="hidden md:block absolute left-1/2 -translate-x-1/2 object-cover"/>
            <img src={main_mob} alt="" className="block sm:hidden w-full absolute h-full object-cover"/>
            <img src={main_tab} alt="" className="hidden sm:block md:hidden w-full absolute h-full object-cover"/>
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto relative">
                <p className="manrope text-[40px] font-[600] leading-[1.2] xl:text-[96px] uppercase mt-[80px] xl:mt-[145px]">{t('home.1')}</p>
                <p className="spanmain text-black manrope text-[32px] xl:text-[64px] font-[600] leading-[1.2] uppercase ml-[30px] xl:ml-[180px]"><Trans>{t('home.2')}</Trans></p>
                <Button className="mt-[160px] xl:mt-[60px]"/>
            </div>
        </div>
    );
}

export default Main;