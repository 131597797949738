import React from "react";

import { useTranslation, Trans } from "react-i18next";

import vec1 from '../../img/AboutUs/vec1.png'

function What () {

    const { t } = useTranslation();

    return(
        <div className="overflow-hidden relative pt-[80px] xl:pt-[160px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto z-50 relative flex flex-col xl:flex-row justify-between items-center">
                <div>
                    <p className="manrope leading-[1.4] text-[24px] xl:text-[48px] text-black spanmain"><Trans>{t('aboutus.21')}</Trans></p>
                    <p className="roboto leading-[1.5] text-[16px] xl:text-[20px] text-[#000000B2] mt-[20px] xl:mt-[40px] max-w-[600px]"><Trans>{t('aboutus.22')}</Trans></p>
                    <p className="roboto leading-[1.5] text-[16px] xl:text-[16px] text-[#000000B2] mt-[20px] xl:mt-[40px] max-w-[600px]"><Trans>{t('aboutus.23')}</Trans></p>
                    <p className="roboto leading-[1.5] text-[16px] xl:text-[16px] text-[#000000B2] mt-[20px] max-w-[600px] font-[300]"><Trans>{t('aboutus.24')}</Trans></p>
                </div>
                <div className="mt-[20px] xl:mt-0">
                    <img src={vec1} alt="" />
                </div>
            </div>
        </div>
    );
}

export default What;