import React, { useState } from "react";

import { useTranslation, Trans } from "react-i18next";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';

import see from '../../img/Home/see.png'

import sw1 from '../../img/Home/swiper/sw1.png'
import sw2 from '../../img/Home/swiper/sw2.png'
import sw3 from '../../img/Home/swiper/sw3.png'
import sw4 from '../../img/Home/swiper/sw4.png'
import sw5 from '../../img/Home/swiper/sw5.png'
import sw6 from '../../img/Home/swiper/sw6.png'

import star from '../../img/Home/swiper/star.png'

const data = [
    {
        name: "StarTrader91",
        text: "home.25",
        sw: sw1,
    },
    {
        name: "MarketMaverick",
        text: "home.26",
        sw: sw2,
    },
    {
        name: "CryptoExplorerX",
        text: "home.27",
        sw: sw3,
    },
    {
        name: "ProfitPioneer55",
        text: "home.28",
        sw: sw4,
    },
    {
        name: "TradeMaestro23",
        text: "home.29",
        sw: sw5,
    },
    {
        name: "GreenPortfolioPro",
        text: "home.30",
        sw: sw6,
    },
]

function SeeWhat () {

    const { t } = useTranslation();

    const [swiper, setSwiper] = useState({});

      
    return(
        <div className="overflow-hidden relative pt-[80px] xl:pt-[160px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col xl:flex-row justify-between xl:pb-[110px]">
                <div className="xl:w-[600px]">
                    <p className="manrope leading-[1.2] text-[24px] xl:text-[48px] text-black max-w-[393px]">{t('home.24')}</p>
                    <div className='flex mt-[20px] xl:mt-[150px] xl:gap-[40px]'>
                        <div onClick={() => swiper.slidePrev()} className="button_swiper cursor-pointer scale-75 xl:scale-100">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M19 12L5 12" stroke="#145B45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12 5L5 12L12 19" stroke="#145B45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>  
                        </div>
                        <div onClick={() => swiper.slideNext()} className="button_swiper cursor-pointer scale-75 xl:scale-100">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M5 12L19 12" stroke="#145B45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12 19L19 12L12 5" stroke="#145B45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <img src={see} alt="" className="absolute right-0 hidden xl:block"/>
                <div className="w-full xl:w-[870px] xl:absolute right-0 mt-[20px] xl:mt-[100px]">
                    <Swiper
                        modules={[Navigation, Pagination, Autoplay]}
                        slidesPerView={1}
                        loop={true}
                        onInit={(e) => {
                            setSwiper(e);
                        }}
                        breakpoints={{
                            300: {
                            slidesPerView: 1,
                            },
                            1280: {
                            slidesPerView: 1.7,
                            },
                        }}
                    >
                        {data.map((dat, index) => (
                            <SwiperSlide key={index}>
                                <div className="xl:w-[496px] h-[304px] bg-white rounded-[40px] p-[20px] xl:p-[40px] border">
                                    <div className="flex gap-[20px] items-end">
                                        <img src={dat.sw} alt="" />
                                        <p className="mb-[6px] text-[#000000B2] roboto text-[14px] xl:text-[16px]">{dat.name}</p>
                                        <img src={star} alt="" className="mb-[12px]"/>
                                    </div>
                                    <p className="mt-[20px] text-[16px] text-[#000000B2] roboto font-[300] leading-[1.2]">{t(dat.text)}</p>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </div>
    );
}

export default SeeWhat;