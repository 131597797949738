import React from "react";

import { useTranslation } from "react-i18next";

function Button ( { className } ) {

    const { t } = useTranslation();
      
    return(
        <div className={className}>
            <a href="https://user.asset-wealth.org/">
                <div className="button_main flex cursor-pointer">
                    <p className="text-white uppercase manrope font-[700] text-[16px]">{t('home.3')}</p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect width="24" height="24" rx="12" fill="white"/>
                        <path d="M7 12L17 12" stroke="#145B45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M13 7L18 12L13 17" stroke="#145B45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </a>
        </div>
    );
}

export default Button;