import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom"

import { useTranslation } from "react-i18next";

import logo from "../img/logo.png"

function MobileMenu() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const location = useLocation();

    const { t } = useTranslation();

    return (
        <div>
            <div className="relative z-[999] w-[30px] h-[25px] cursor-poroboto flex flex-col justify-between xl::hidden" onClick={toggleMenu}>
                <div className={`w-full h-[1px] transition duration-300 transform ${isOpen ? 'rotate-45 translate-y-[9px] bg-[#000] w-[35px]' : 'bg-[#000]'}`}></div>
                <div className={`w-full h-[1px] bg-[#000] transition duration-300 transform ${isOpen ? 'hidden' : 'block'}`}></div>
                <div className={`w-full h-[1px]  transition duration-300 transform ${isOpen ? '-rotate-45 -translate-y-[15px] bg-[#000] w-[35px]' : 'bg-[#000]'}`}></div>
            </div>
            <div className={`fixed top-0 right-0 bottom-0 w-full md:w-1/2 bg-white z-50 transform transition-all duration-300 ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}>
                <div className="flex flex-col items-center mt-[50px] gap-[15px]">
                    <img src={logo} alt="" className="w-[40px]"/>
                    <Link to="/products"><p className={`text-[18px] roboto ${location.pathname === '/products' ? 'font-bold' : 'font-medium'}`} onClick={toggleMenu}>{t('header1')}</p></Link>
                    <Link to="/security"><p className={`text-[18px] roboto ${location.pathname === '/security' ? 'font-bold' : 'font-medium'}`} onClick={toggleMenu}>{t('header2')}</p></Link>
                    <Link to="/accounts"><p className={`text-[18px] roboto ${location.pathname === '/accounts' ? 'font-bold' : 'font-medium'}`} onClick={toggleMenu}>{t('header3')}</p></Link>
                    <Link to="/education"><p className={`text-[18px] roboto ${location.pathname === '/education' ? 'font-bold' : 'font-medium'}`} onClick={toggleMenu}>{t('header4')}</p></Link>
                    <Link to="/aboutus"><p className={`text-[18px] roboto ${location.pathname === '/aboutus' ? 'font-bold' : 'font-medium'}`} onClick={toggleMenu}>{t('header5')}</p></Link>
                    <a href="https://user.asset-wealth.org/">
                        <div className="button cursor-poroboto">
                            <p className="roboto text-white text-[16px]">{t('header6')}</p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default MobileMenu;