import React from 'react';

import { useTranslation } from "react-i18next";

import logo from '../img/logo.png'

const { hostname } = document.location;

function Footer() {

  const { t } = useTranslation();

  return (
    <div className='bg-[#FFF] z-10 relative'>
      <div className='flex flex-col xl:flex-row container mx-auto pt-[160px]'>
        <img src={logo} alt="" className='w-[100px] xl:h-[45px] ml-[30px] xl:ml-0'/>
        <div className='ml-[30px] xl:ml-[150px] gap-[15px] flex flex-col mt-[30px] xl:mt-0'>
          <p className='text-[#000000B2] roboto text-[14px] xl:text-[16px] font-normal'>{t('footer1')} +441312028936</p>
          <div>
            <p className='text-[#000000B2] roboto text-[14px] xl:text-[16px] font-normal'>{t('footer2')} Flat 4 99 Kennington Park Road, London, United Kingdom, SE11 4JJ</p>
          </div>
          <p className='text-[#000000B2] roboto text-[14px] xl:text-[16px] font-normal'>{t('footer3')} support@{hostname}</p>
        </div>
        <div className='ml-[30px] xl:ml-[450px] gap-[15px] flex flex-col mt-[15px] xl:mt-0'>
          <a href="/terms.pdf"><p className='text-[#000000B2] roboto text-[14px] xl:text-[16px] font-normal'>{t('footer4')}</p></a>
          <a href="/policy.pdf"><p className='text-[#000000B2] roboto text-[14px] xl:text-[16px] font-normal'>{t('footer5')}</p></a>
          <a href="/aml.pdf"><p className='text-[#000000B2] roboto text-[14px] xl:text-[16px] font-normal'>{t('footer6')}</p></a>
        </div>
      </div>
      <div className='flex flex-col container mx-auto pl-[30px] xl:pl-[250px] pt-[40px]'>
        <p className='text-[#000000B2] roboto text-[12px] font-normal'>{t('footer7')}</p>
        <p className='text-[#000000B2] roboto text-[12px] font-light mt-[6px] pr-[30px] xl:pr-0'>{t('footer8')}</p>
        <p className='flex justify-end pb-[150px] mt-[14px] roboto text-[16px] text-[#000000B2] pr-[30px] xl:pr-0'>Asset Wealth © 2024. All rights reserved.</p>      
      </div>
    </div>
  );
}

export default Footer;